import { Select, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Button } from "src/components/Button";
import { FormInput } from "src/components/inputs";
import { branchList } from "src/features/auth/authSlice";
import { Label } from "src/components/inputs";
import { v4 as uuidv4 } from "uuid";

import {
  SuccessNotification,
  ErrorNotification,
} from "src/helpers/helperFunction";
import {
  useBankListQuery,
  useWithDrawFromStationMutation,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";

export const WithDrawalMoal = ({ closeModal }: any) => {
  const [withDrawMutation, widthDrawalResult] =
    useWithDrawFromStationMutation();
  const Formik = useFormik<any>({
    initialValues: {
      amount: 0,
      destinationBankUUID: "",
      destinationBankAccountNumber: "",
      purpose: "",
      idempotentKey: "",
    },
    validateOnBlur: true,
    validateOnChange: true,

    onSubmit: async (values) => {
      try {
        const response = await withDrawMutation({
          ...values,
          amount: Number(values.amount),
          destinationBankUUID: values.destinationBankUUID.uuid,
          idempotentKey: uuidv4(),
        }).unwrap();

        SuccessNotification("Successful");
      } catch (error: any) {
        ErrorNotification(error?.data?.message);
      }
      closeModal();
    },
  });

  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[20px] text-gray-700 ";

  const res = useBankListQuery({});

  const hqBranches = useSelector(branchList);
  const datalistRef = useRef<any>();
  const handleDatalistChange = (event: any) => {
    const joinedName = event?.target?.value?.toLowerCase();
    const bankUUID = res?.data?.find((item: any) =>
      item?.name?.toLowerCase().includes(joinedName)
    );

    Formik.setFieldValue("destinationBankUUID", bankUUID);
  };

  return (
    <form
      onSubmit={Formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center p-4 h-full"
    >
      <div className="px-6 my-3 py-3 bg-red-200 text-red-600 ">
        <p className="text-sm">
          Note: N60 will be deducted from your account for this transaction.
        </p>
      </div>
      <div className="grid grid-cols-1 w-full gap-x-2 gap-y-4 content-center">
        <FormInput
          name="Amount"
          type="text"
          onChange={(e: any) => Formik.setFieldValue("amount", e.target.value)}
          value={Formik.values.amount}
          id="amount"
        />
        <FormInput
          name="Destination Acccount Number"
          type="text"
          onChange={(e: any) =>
            Formik.setFieldValue("destinationBankAccountNumber", e.target.value)
          }
          value={Formik.values.destinationBankAccountNumber}
          id="destinationBankAccountNumber"
        />
        <div>
          <Label name="Wallet" styles={labelStyles} />
          <Select
            fullWidth
            className=" rounded-lg border border-gray-300 bg-[#D9D9D9]"
            sx={{ borderRadius: 2.5, height: 50 }}
            onChange={(e: any) => Formik.setFieldValue("from", e.target.value)}
          >
            <MenuItem value="branchesBalance">Branch Balance</MenuItem>
            <MenuItem value="availableBalance">Available Balance</MenuItem>
          </Select>
        </div>

        <div>
          <Label name="Bank" styles={labelStyles} />

          <div>
            <input
              list="browsers"
              onChange={handleDatalistChange}
              className="py-2.5 rounded-lg w-full border border-gray-300 bg-[#D9D9D9] px-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <datalist id="browsers" ref={datalistRef}>
              {res?.data?.map((item: any) => {
                return (
                  <option key={item?.uuid} id={item?.name} value={item?.name}>
                    {item?.name}
                  </option>
                );
              })}
            </datalist>
          </div>
        </div>

        {Formik.values?.from === "branchesBalance" ? (
          <div>
            <Label name="Branches" styles={labelStyles} />
            <Select
              className="bg-[#D9D9D9]"
              fullWidth
              sx={{ borderRadius: 2, height: 50 }}
              onChange={(e: any) =>
                Formik.setFieldValue("stationBranch", e.target.value)
              }
            >
              {hqBranches?.map((item: any) => {
                return (
                  <MenuItem key={item?.uuid} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        ) : null}

        <div className="w-full">
          <Label name="Purpose" styles={labelStyles} />
          <textarea
            className="w-full rounded-lg border p-3 bg-[#D9D9D9]"
            placeholder="Add description..."
            rows={3}
            onChange={(e) => Formik.setFieldValue("purpose", e.target.value)}
          />
        </div>
      </div>

      <Button
        text="Withdraw"
        disabled={widthDrawalResult.isLoading}
        showModal={widthDrawalResult.isLoading}
        className="h-11 font-bold text-white mt-6  rounded-lg px-5 bg-[#002E66] flex items-center flex-row flex-nowrap justify-center w-full"
        type="submit"
      />
    </form>
  );
};
