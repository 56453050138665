import { Checkbox, IconButton } from "@mui/material";
import { ChangeEventHandler, useState } from "react";
import { ReactElement } from "react";
import { FilterList, Visibility, VisibilityOff } from "@mui/icons-material";
import { SearchIcon } from "./Icons";
import { inputType } from "src/helpers/alias";
import { SelectType } from "./SelectInput";

// placeholder,
// type propsType = {
// 	name: string;
// 	styles?: string;
// };
export function Label(props: { styles: string; name: string }) {
  // const { styles  } = props;

  return <label className={props.styles}>{props.name}</label>;
}

const Error = ({ error }: { error: string }): ReactElement => (
  <p className="mt-1 text-xs font-thin text-red-500 text-start capitalize">
    {error}
  </p>
);

export const FormInput = (props: inputType) => {
  const {
    name,
    id,
    type,
    onChange,
    onBlur,
    disabled,
    placeholder,
    touched,
    border,
    error,
    value,
    ref,
    styles = `h-[40px] w-full border border-gray-300 px-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500  bg-[#D9D9D9]`,
    labelStyles = "block mb-[6px] text-black text-start font-normal text-[20px] text-gray-700",
    width = "100%",
  } = props;

  const compstyle = `${styles} ${border ? border : "rounded-lg"} ${
    props.error && props.touched ? "border-red-500" : "border-gray-300"
  }`;
  return (
    <div style={{ width: width }}>
      <Label name={name} styles={labelStyles} />
      <input
        name={id}
        id={id}
        ref={ref}
        type={type}
        className={compstyle}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
      />
      {error && touched ? <Error error={error} /> : null}
    </div>
  );
};
export const PasswordInput = (props: inputType) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const compstyle = `h-54 w-full border-gray-300 px-4 focus:border-transparent focus:outline-none focus:ring-2  focus:ring-blue-500 bg-[#D9D9D9] h-full ${
    props?.border ? props?.border : "rounded-xl"
  } 
  ${props.error && props.touched ? "border-red-500" : "border-gray-300"}`;
  return (
    <div className={`${props.width || "w-[70%]"} `}>
      <Label
        name={props.name}
        styles={
          props.labelStyles ||
          "block mb-[0px] text-start font-normal text-[20px] text-gray-700"
        }
      />
      <div
        className={`flex justify-between items-center h-[40px] ${
          props?.border ? props?.border : "rounded-lg"
        } w-full border border-gray-300 pr-4  bg-[#D9D9D9]  ${
          props.error && props.touched ? "border-red-500" : "border-gray-300"
        }`}
      >
        <input
          name={props.inputName}
          id={props.id}
          type={showPassword ? "text" : "password"}
          className={compstyle}
          onChange={props.onChange}
          value={props.value}
          onBlur={props.onBlur}
          disabled={props.disabled}
          placeholder={props.placeholder}
        />

        <IconButton
          className="text-sm bg-transparent"
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? (
            <VisibilityOff fontSize="small" />
          ) : (
            <Visibility fontSize="small" />
          )}
        </IconButton>
      </div>

      {props.error && props.touched ? <Error error={props.error} /> : null}
    </div>
  );
};

export const CheckBox = ({
  onChange,
}: {
  onChange: ChangeEventHandler<HTMLInputElement>;
}): ReactElement => (
  <Checkbox
    defaultChecked
    onChange={onChange}
    sx={{
      color: "#002E66",
      borderRadius: "38px",
      "&.Mui-checked": {
        color: "#002E66",
      },
    }}
  />
);

type searchInput = {
  name: string;
  onChange: ChangeEventHandler;
  placeholder: string;
  value: string | number;
};
export const SearchInput = (props: searchInput) => {
  return (
    <div className="flex w-[256px] h-11 justify-start  items-center gap-2 rounded-lg border-2 border-[#D0D5DD] bg-[#D9D9D9] px-[18px]">
      <SearchIcon />
      <input
        type="text"
        name={props.name}
        onChange={props.onChange}
        autoComplete={"false"}
        value={props?.value}
        placeholder={props.placeholder}
        // className="w-full outline-none"
        className="rounded-[15px] px-[14px] w-full focus:outline-none bg-transparent placeholder:text-[#1E1E1E]"
      />
    </div>
  );
};

interface TextAreaTypes {
  onChange: ChangeEventHandler<HTMLTextAreaElement> | undefined;
  [index: string]: string | any;
}
export const TextArea = (props: TextAreaTypes) => (
  <div className="w-full">
    <Label name={props.name} styles={props.labelStyles} />
    <textarea
      className="w-full rounded-lg p-3 bg-[#D9D9D9]"
      placeholder="Add description..."
      rows={3}
      id={props.id}
      name={props.id}
      onChange={props.onChange}
      value={props.value}
      disabled={props.disabled}
    />
    {props.error && props.touched ? <Error error={props.error} /> : null}
  </div>
);

interface SelectInputType {
  labelStyles: string;
  data: string[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  name: string;
  value: string;
  id: string;
  disabled?: boolean;
}

export const SelectInput = (props: SelectInputType) => (
  <div className="w-full">
    <Label name={props.name} styles={props.labelStyles} />

    <select
      className="mt-1 py-0 h-[40px] rounded-lg w-full border border-gray-300 px-4 text-[14px] bg-[#D9D9D9]"
      name={props.id}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    >
      <option>Select</option>
      {props.data.map((_v: string, i: number) => (
        <option key={i} value={_v}>
          {_v}
        </option>
      ))}
    </select>
  </div>
);

export const SelectInputNew = (props: {
  onChange: ChangeEventHandler<HTMLSelectElement>;
  filteredValue: string;
  tabData: SelectType[];
  iniText?: string;
  className?: string;
}): ReactElement => {
  const classN = props?.className || "  w-[256px]  h-11 ";
  return (
    <div className={classN}>
      <div className="border border-gray-200 rounded-lg px-2 ">
        <FilterList />
        <select
          className="py-2 px-4 bg-transparent w-[70%]"
          value={props.filteredValue}
          onChange={props.onChange}
        >
          <option value={""}>{props.iniText || "Filter"}</option>
          {props?.tabData?.map((dt: SelectType) => (
            <option key={dt.id} value={dt?.value.trim()}>
              {dt?.label.trim()}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
