import { useFormik } from "formik";
import React, { useState } from "react";
import { Button } from "./Button";
import * as yup from "yup";
import { SelectInput, SelectInputNew } from "./inputs";
import { useAddProductsMutation } from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { useAuth } from "src/hooks/useAuth";
import InputComp from "./InputComp";
import CustomizedSwitches from "./SwitchComp";
import {
  ErrorNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import useCustomLocation from "src/hooks/useCustomLocation";
import { useGetStationProductsQuery } from "src/api/setttingsApislice";
//SUBSCRIPTION_PRICE

export default function AddProducts({ onClose }: any) {
  const [addProductMutation, result] = useAddProductsMutation();
  const [selectedProduct, setselectedProduct] = useState("");
  const { slicedPath } = useCustomLocation();
  const stationProducts = useGetStationProductsQuery({});

  const formik = useFormik({
    initialValues: {
      name: "",
      unit: "",
      price: "",
      isAvailable: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
    // validationSchema: yup.object().shape({
    // 	products: yup.array({
    // 		name: yup.string().label("Product name").required(),
    // 		unit: yup.string().label("Product unit").required(),
    // 		price: yup.string().label("Product price").required(),
    // 	}),
    // }),
    onSubmit: async (values) => {
      try {
        const response = await addProductMutation({
          id: slicedPath[2],
          products: [{ ...values, name: selectedProduct }],
        }).unwrap();
        SuccessNotification("Product Added Successfully");
      } catch (error: any) {
        ErrorNotification(error?.data?.message);
      }

      onClose();
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="px-4">
        <SelectInputNew
          className="w-full h-11 mt-6"
          iniText="Product name"
          filteredValue={selectedProduct}
          onChange={(e) => {
            setselectedProduct(e.target.value);
          }}
          tabData={stationProducts?.data?.products?.data?.map((sp: any) => ({
            value: sp.productNameAbb,
            id: sp.productNameAbb,
            label: `${sp.productName} - ${sp.productNameAbb}`,
          }))}
        />
        <InputComp
          label="unit"
          placeholder="Product unit"
          {...formik.getFieldProps(`unit`)}
        />

        <InputComp
          type="number"
          label="Price"
          placeholder="Product price"
          {...formik.getFieldProps(`price`)}
        />

        <div>
          <label
            htmlFor=""
            className="block mb-[6px]  text-start font-normal text-[14px] text-black ml-5 my-6"
          >
            Is The Product Available
          </label>
          <div className="pl-6">
            <CustomizedSwitches
              off="No"
              on="Yes"
              {...formik.getFieldProps(`isAvailable`)}
            />
          </div>
        </div>
        <div className="px-6 mt-5">
          <p className="text-sm">
            Note: N{process.env.REACT_APP_SUBSCRIPTION_PRICE || "500"} will be
            deducted from your account for this transaction.
          </p>
        </div>
        <Button
          text="Add New Product"
          showModal={result.isLoading}
          // disabled={props.apiResult?.isLoading}
          // showModal={props.apiResult?.isLoading}
          className="h-11 font-bold text-white mt-6 rounded-lg px-5 bg-[#002E66] flex items-center flex-row flex-nowrap justify-center w-full"
          type="submit"
        />
      </div>
    </form>
  );
}

let data = [
  { name: "Yes", value: true },
  { name: "No", value: true },
];
