import React from "react";
import { useFormik } from "formik";
import { Button } from "./Button";
import { useAddAmenitiesMutation } from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { useAuth } from "src/hooks/useAuth";
import InputComp from "./InputComp";

import {
  ErrorNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import { IconButton } from "@mui/material";
import { RemoveCircle } from "@mui/icons-material";
import useCustomLocation from "src/hooks/useCustomLocation";

export default function AddAmenities({ onClose }: any) {
  const { slicedPath } = useCustomLocation();

  const [addAmenitiesMutation, result] = useAddAmenitiesMutation();
  const formik = useFormik({
    initialValues: {
      amenities: [
        {
          name: "",
        },
      ],
    },
    validateOnBlur: true,
    validateOnChange: true,
    // validationSchema: yup.object().shape({
    // 	products: yup.array({
    // 		name: yup.string().label("Product name").required(),
    // 		unit: yup.string().label("Product unit").required(),
    // 		price: yup.string().label("Product price").required(),
    // 	}),
    // }),
    onSubmit: async (values) => {
      try {
        const response = await addAmenitiesMutation({
          id: slicedPath[2],
          ...values,
        }).unwrap();
        SuccessNotification("NRF Added Successfully");
      } catch (error: any) {
        ErrorNotification(error?.data?.message);
      }

      onClose();
    },
  });

  function addMore() {
    formik.setFieldValue("amenities", [...formik.values.amenities, {}]);
  }
  function removeAmenities(id: number) {
    formik.setFieldValue("amenities", [
      ...formik.values.amenities.filter((_, i) => i !== id),
    ]);
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="px-4">
        {formik.values.amenities.map((d, i) => {
          return (
            <div className="flex items-center justify-between  gap-x-4">
              <div className="w-full">
                <InputComp
                  label={`NFR ${i + 1}`}
                  placeholder={`NFR ${i + 1}`}
                  {...formik.getFieldProps(`amenities.${[i]}.name`)}
                />
              </div>
              <div className="flex items-center  mt-12">
                <IconButton
                  className="mt-6  block"
                  onClick={() => removeAmenities(i)}
                >
                  <RemoveCircle />
                </IconButton>
              </div>
            </div>
          );
        })}
        <div className="px-6 mt-2">
          <button className="mt-3" type="button" onClick={addMore}>
            Add More
          </button>
        </div>
        <div className="px-6 mt-5">
          <p className="text-sm">
            Note: N{process.env.REACT_APP_SUBSCRIPTION_PRICE || "500"} will be
            deducted from your account for this transaction.
          </p>
        </div>
        <Button
          text="Add New Amenities"
          showModal={result.isLoading}
          // disabled={props.apiResult?.isLoading}
          // showModal={props.apiResult?.isLoading}
          className="h-11 font-bold text-white mt-6 rounded-lg px-5 bg-[#002E66] flex items-center flex-row flex-nowrap justify-center w-full"
          type="submit"
        />
      </div>
    </form>
  );
}
