import { SetStateAction, useCallback, useMemo, useState } from "react";
import { Button } from "src/components/Button";
import { FormModal } from "src/components/ModalComp";
import {
  CurrencyFormatter,
  ErrorNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import Wallet from "src/assets/img/walletbtn.svg";
import {
  useApproveWithdrawalRequestMutation,
  useGetAllWidthdrawRequestQuery,
  useVerifyTransactionPinMutation,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { LoaderContainer } from "src/components/LoaderContainer";
import { format } from "date-fns";
import { Tabs, Tab } from "@mui/material";
import RequestDetails from "./components/RequestDetails";
import NotificationIndicator from "./components/Notification";
import { PasswordInput } from "src/components/inputs";
import { useAuth } from "src/hooks/useAuth";
import { useParams } from "react-router-dom";

const WithDrawal = () => {
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [value, setValue] = useState(0);
  const { user } = useAuth();
  const params = useParams();

  const allWithdrawalRequestQuery = useGetAllWidthdrawRequestQuery({
    populate: "stationBranch",
    stationBranch: params?.id,
    for: "station_branch",
  });

  const branchWithdrawRequest = useCallback(
    (status: string) =>
      allWithdrawalRequestQuery?.data?.data?.data?.filter(
        (item: any) => item?.status === status
      ),
    [allWithdrawalRequestQuery]
  );

  const handleWidthDrawal = (item: any) => {
    setShowNotification((prevState) => !prevState);

    setSelectedItem(item);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <section>
      <Tabs value={value} onChange={handleChange} aria-label="Order list">
        <Tab
          label={`Pending (${branchWithdrawRequest("pending")?.length || 0})`}
          tabIndex={0}
        />
        <Tab
          label={`Approved (${branchWithdrawRequest("approved")?.length || 0})`}
          tabIndex={1}
        />
        <Tab
          label={`Rejected (${branchWithdrawRequest("rejected")?.length || 0})`}
          tabIndex={2}
        />
        {/* <Tab label="Completed" tabIndex={3} /> */}
      </Tabs>
      <LoaderContainer data={allWithdrawalRequestQuery}>
        <article>
          {value === 0 ? (
            <>
              {branchWithdrawRequest("pending")?.length > 0 ? (
                <>
                  {branchWithdrawRequest("pending")?.map((item: any) => {
                    return (
                      <button
                        onClick={() => handleWidthDrawal(item)}
                        className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative"
                      >
                        <img src={Wallet} alt="wallet" />
                        <RequestDetails
                          name={item?.stationBranch?.name}
                          amount={item?.payload?.amount}
                          date={item?.createdAt}
                        />
                        <NotificationIndicator status="pending" />
                      </button>
                    );
                  })}
                </>
              ) : (
                <div className="w-full h-56  flex items-center justify-center font-bold ">
                  No pending withdrawal Request Available
                </div>
              )}
            </>
          ) : null}
          {value === 1 ? (
            <>
              {branchWithdrawRequest("approved")?.length > 0 ? (
                <>
                  {branchWithdrawRequest("approved")?.map((item: any) => {
                    return (
                      <button className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative">
                        <img src={Wallet} alt="wallet" />
                        <RequestDetails
                          name={item?.stationBranch?.name}
                          amount={item?.payload?.amount}
                          desc={item?.payload?.purpose}
                          date={item?.createdAt}
                          updatedAt={item?.updatedAt}
                          status={item?.status}
                        />
                        <NotificationIndicator status="approved" />
                      </button>
                    );
                  })}
                </>
              ) : (
                <div className="w-full h-56  flex items-center justify-center font-bold ">
                  No approved withdrawal Request Available
                </div>
              )}
            </>
          ) : null}
          {value === 2 ? (
            <>
              {branchWithdrawRequest("rejected")?.length > 0 ? (
                <>
                  {branchWithdrawRequest("rejected")?.map((item: any) => {
                    return (
                      <button className="w-full py-5 mt-3 shadow-lg bg-white rounded-lg flex items-center gap-10 px-6 font-semibold text-primary text-sm text-start relative">
                        <img src={Wallet} alt="wallet" />
                        <RequestDetails
                          name={item?.stationBranch?.name}
                          amount={item?.payload?.amount}
                          date={item?.createdAt}
                        />
                        <NotificationIndicator status="rejected" />
                      </button>
                    );
                  })}
                </>
              ) : (
                <div className="w-full h-56  flex items-center justify-center font-bold ">
                  No declined Request
                </div>
              )}
            </>
          ) : null}
        </article>
      </LoaderContainer>

      {showNotification ? (
        <FormModal
          name="Withdrawal request"
          onClick={() => setShowNotification((prevState) => !prevState)}
        >
          <WidthDrawModal
            toggleState={() => setShowNotification((prevState) => !prevState)}
            selectedItem={selectedItem}
          />
        </FormModal>
      ) : null}
    </section>
  );
};

export default WithDrawal;

const WidthDrawModal = ({ selectedItem, toggleState }: any) => {
  const [approveWidthdrawalMutation, widthdrawalRequest] =
    useApproveWithdrawalRequestMutation();
  const [verifyTransactionPin, { isLoading: isVerifyingPin }] =
    useVerifyTransactionPinMutation();

  const [approvedState, setApprovedState] = useState(false);
  const [pin, setPin] = useState("");
  const [isPinVerified, setIsPinVerified] = useState(false);
  const [isEnterPin, setIsEnterPin] = useState(false);
  const [toUpdate, setToUpdate] = useState(false);

  const handleAction = async (isApproved: boolean) => {
    setToUpdate(isApproved);
    setIsEnterPin(true);
  };

  const handleConfirmPin = async () => {
    try {
      const response: any = await verifyTransactionPin({
        pin: pin,
      });

      if (response.data.error) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    } finally {
    }
  };

  const handleApprovedRequest = async (isApproved: boolean) => {
    const verifyPinResponse = await handleConfirmPin();

    if (!verifyPinResponse) {
      ErrorNotification("Invalid Pin");
      return;
    }

    setApprovedState(isApproved);
    try {
      const response: any = await approveWidthdrawalMutation({
        id: selectedItem?.id,
        body: { status: isApproved ? "approved" : "rejected" },
      }).unwrap();
      SuccessNotification(
        isApproved ? response.message : "Request declined successfully"
      );
    } catch (error: any) {
      ErrorNotification(error?.data?.message || "An error occurred");
      console.error(error, "error");
    } finally {
      setIsEnterPin(false);
    }
    toggleState();
  };

  const verifyPin = () => {
    if (pin.length === 4 && pin === "1234") {
      // Replace "1234" with the logic or API call to verify the PIN
      setIsPinVerified(true);
      alert("PIN verified successfully!");
    } else {
      alert("Invalid PIN. Please try again.");
      setIsPinVerified(false);
    }
  };

  return (
    <div className="w-full flex flex-col justify-center py-4 px-6 h-full text-sm gap-6">
      <div>
        <h1 className="text-lightgray">Branch Name</h1>
        <p>{selectedItem?.stationBranch?.name || "Name not available"}</p>
      </div>
      <div>
        <h1 className="text-lightgray">Description</h1>
        <p>{selectedItem?.payload?.purpose}</p>
      </div>
      <div>
        <h1 className="text-lightgray">Amount</h1>
        <p>{CurrencyFormatter(selectedItem?.payload?.amount)}</p>
      </div>
      <div>
        <h1 className="text-lightgray">Request Date</h1>
        <p>{format(new Date(selectedItem?.createdAt), "d/MM/yyyy h:m:s a")}</p>
      </div>

      {isEnterPin && (
        <div>
          <h1 className=" mt-0">Enter transaction pin to proceed</h1>
          <div className="flex flex-col w-full items-center gap-4 mt-5">
            <div className="w-full -mt-4">
              <PasswordInput
                width="w-full"
                id={"pin"}
                name={"Pin"}
                inputName={"pin"}
                type={"text"}
                onChange={(e: any) => {
                  setPin(e.target.value);
                }}
                value={pin}
                disabled={isVerifyingPin}
                // error={_v.error}
                // touched={_v.touched}
              />
            </div>
            <div className="flex w-full justify-between items-center">
              <div className=" w-[48%]   h-[41px] mb-[19px]">
                <Button
                  onClick={() => handleApprovedRequest(true)}
                  text={toUpdate ? "Process Approval" : "Process Decline"}
                  className="h-[41px]  w-full font-bold bg-primary rounded-full text-white flex items-center justify-center"
                  type="button"
                  disabled={isVerifyingPin}
                  showModal={widthdrawalRequest.isLoading || isVerifyingPin}
                />
              </div>
              <div className=" w-[48%]  h-[41px] mb-[19px]">
                <Button
                  onClick={() => {
                    setIsEnterPin(false);
                  }}
                  disabled={isVerifyingPin}
                  text="Cancel"
                  className="h-[41px]  w-full font-bold bg-primary rounded-full text-white flex items-center justify-center"
                  type="button"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {!isEnterPin && (
        <div className="flex w-full justify-between items-center">
          <Button
            onClick={() => handleAction(true)}
            text="Approve"
            className="h-[41px] w-[48%] font-bold  bg-primary rounded-full text-white flex items-center justify-center"
            type="button"
            showIcon={false}
            showModal={widthdrawalRequest.isLoading && approvedState}
          />
          <Button
            text="Decline"
            className="h-[41px] w-[48%]  font-bold bg-red-600 border border-red-600 text-white rounded-full flex items-center justify-center"
            type="button"
            showIcon={false}
            showModal={widthdrawalRequest.isLoading && !approvedState}
            onClick={() => handleAction(false)}
          />
        </div>
      )}

      {/* <div className="w-full h-[41px]">
        <Button
          onClick={() => handleAction(false)}
          text="Decline"
          className="h-full w-full font-bold bg-red-600 border border-red-600 text-white rounded-full flex items-center justify-center"
          type="button"
          showModal={widthdrawalRequest.isLoading && !approvedState}
        />
      </div> */}
    </div>
  );
};
