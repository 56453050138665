import React, { Fragment, useMemo, useState } from "react";

import SummaryCard from "src/hq-admin/hq-pages/Depot/components/SummaryCard";
import { useLocation, useParams } from "react-router-dom";
import {
  CurrencyFormatter,
  handleDateFormat,
  handleFormatDate,
} from "src/helpers/helperFunction";
import Transaction from "src/hq-admin/hq-pages/Transaction";
import { useGetAllHQTransactionsQuery } from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { format } from "date-fns";
import { GiCoins } from "react-icons/gi";
import { FaSortAmountUpAlt } from "react-icons/fa";
import { SelectInput } from "src/components/SelectInput";
import { SOURCES, STATUS, TYPES, filterData } from "src/helpers/data";
import { Badge } from "src/components/Badge";
import { CardButton } from "src/components/Card";
import { Attendant } from "src/components/Icons";
import walletBtn from "src/assets/img/walletbtn.svg";
import Branch from "src/assets/img/branches.svg";
import { cardBtnType } from "src/helpers/alias";
import {
  useFetchAttendantSummaryQuery,
  useFetchMeterReadingsQuery,
} from "src/hq-admin/hq-api/manageHqApiSlice";
import useCustomLocation from "src/hooks/useCustomLocation";
import { useDebounce } from "src/hooks/useDebounce";

const headCells: any[] = [
  {
    id: "date",
    minWidth: 210,
    label: "Transaction date",
  },
  {
    id: "referenceId",
    minWidth: 170,
    label: "Reference",
  },

  {
    id: "amount",
    minWidth: 170,
    label: "Transaction Amount (N)",
  },
  {
    id: "accountName",
    minWidth: 170,
    label: "Payer Name",
  },
  {
    id: "category",
    minWidth: 170,
    label: "Category",
  },
  {
    id: "type",
    minWidth: 170,
    label: "Type",
  },

  {
    id: "status",
    minWidth: 170,
    label: "Status",
  },
];
const meterReadingsHeadCells: any[] = [
  {
    id: "pumpNumber",
    minWidth: 170,
    label: "Pump Number",
  },
  {
    id: "openingMeterReadings",
    minWidth: 170,
    label: "Opening Meter Readings",
  },
  {
    id: "closingMeterReadings",
    minWidth: 170,
    label: "Closing Meter Readings",
  },
  {
    id: "createdAt",
    minWidth: 170,
    label: "created",
  },
];

const attendantTabData: cardBtnType[] = [
  {
    id: 1,
    icon: walletBtn,
    name: "Transaction",
  },
  {
    id: 2,
    icon: Branch,
    name: "Meter Readings",
  },
];

export default function SingleAttendentsProfile() {
  const params = useParams();

  const [cardName, setCardName] = useState<any>({
    tab: "transaction",
  });
  const paramStateData = useLocation();
  const [info, setInfo] = useState({
    source: "user_transfer",
  });

  const attendentsProfileData = paramStateData?.state;
  const meterReadingResult = useFetchMeterReadingsQuery({
    pumpAttendant: params?.id,
  });
  const attendantSummary = useFetchAttendantSummaryQuery({
    pumpAttendant: params?.id,
    stationBranch: params?.branchId,
  });

  const [filterParam, setFilterParam] = useState({
    date: "",
  });
  const { debouncedValue: dateDebouncedValue } = useDebounce(
    filterParam.date,
    700
  );

  const allTransactionsResult = useGetAllHQTransactionsQuery({
    pumpAttendant: params?.id,
    limit: 8,
    ...(dateDebouncedValue && { date: dateDebouncedValue }),
    orderBy: "createdAt:desc",
    ...(STATUS.includes(info?.source) && { status: info.source }),
    ...(SOURCES.includes(info?.source) && { source: info.source }),
    ...(TYPES.includes(info?.source) && { type: info.source }),
  });

  const handledAPIResponse = useMemo(() => {
    const transactions = allTransactionsResult?.data?.data || [];

    return transactions?.reduce(
      (acc: { [index: string]: string }[], cur: any | any) => {
        const { amount, id, meta, ...rest } = cur;
        return [
          ...acc,

          {
            ...rest,
            ...meta,
            date: handleFormatDate(cur.createdAt),
            referenceId: cur?.reference,
            doneby: cur?.meta?.from,
            walletId: cur?.meta?.walletNumber,
            transactionType: cur?.meta?.transactionType,
            purpose: cur?.purpose,
            pumpAttendant:
              attendentsProfileData?.firstName +
              " " +
              attendentsProfileData?.lastName,

            amount: CurrencyFormatter(Number(cur?.amount)),
            name: cur?.stationBranch?.name,
            accountName: `${
              cur.source === "instant_wallet"
                ? cur?.meta?.destinationHolderName || "----------"
                : cur?.type === "credit"
                ? cur?.meta?.payerName || "----------"
                : cur?.type === "debit"
                ? cur?.meta?.senderName || "----------"
                : ""
            }`,
            status: cur?.status ? (
              <Badge type="TRANSACTION_STATUS_OPTIONS" status={cur?.status} />
            ) : (
              "----------"
            ),
            category: cur?.category ? (
              <Badge
                type="TRANSACTION_CATEGORY_OPTIONS"
                status={cur?.category}
              />
            ) : (
              "----------"
            ),
            type: cur?.type ? (
              <Badge type="TRANSACTION_TYPE_OPTIONS" status={cur?.type} />
            ) : (
              "----------"
            ),
          },
        ];
      },
      []
    );
  }, [allTransactionsResult]);

  const handledMeterReadingResponse = useMemo(() => {
    const meterReadings = meterReadingResult?.data?.data?.data || [];

    return meterReadings?.reduce(
      (acc: { [index: string]: string }[], cur: any | any) => {
        return [
          ...acc,

          {
            createdAt: handleFormatDate(cur.createdAt),
            stationBranch: cur?.stationBranch,
            pumpNumber: cur?.pumpNumber,
            openingMeterReadings: cur?.openingMeterReadings,
            closingMeterReadings: cur?.closingMeterReadings,
            unit: cur?.unit,
            purpose: cur?.purpose,
            pumpAttendant:
              attendentsProfileData?.firstName +
              " " +
              attendentsProfileData?.lastName,
          },
        ];
      },
      []
    );
  }, [meterReadingResult]);

  // HANDLE CHANGE FOR TABLE TAB
  const handleSelectChange = (event: { target: { value: string } }) => {
    setInfo((prev) => {
      return { ...prev, source: event.target.value };
    });
  };

  const handleTabChange = (newValue: string) => {
    setCardName({
      tab: newValue,
    });
  };
  return (
    <section>
      <article className="w-full h-screen px-2">
        <>
          <div className="grid grid-cols-3 gap-3 w-full">
            <SummaryCard
              icon={<GiCoins className="h-8 w-12 mr-4 text-green-300" />}
              name="Total Sales"
              amount={attendantSummary?.data?.summary?.totalSales || 0}
            />
            <SummaryCard
              icon={
                <FaSortAmountUpAlt className="h-8  w-12 mr-4 text-green-300" />
              }
              name="Total Sales Amount"
              amount={CurrencyFormatter(
                attendantSummary?.data?.summary?.totalSalesAmount
              )}
            />
            <SummaryCard
              icon={<GiCoins className="h-8  w-12 mr-4 text-green-300" />}
              name="Offline Sales"
              amount={attendantSummary?.data?.summary?.offlineSales || 0}
            />
            <SummaryCard
              icon={
                <FaSortAmountUpAlt className="h-8  w-12 mr-4 text-green-300" />
              }
              name="Offline Sales Amount"
              amount={CurrencyFormatter(
                attendantSummary?.data?.summary?.offlineSalesAmount
              )}
            />

            <SummaryCard
              icon={<GiCoins className="h-8  w-12 mr-4 text-green-300" />}
              name="Online Sales"
              amount={attendantSummary?.data?.summary?.onlineSales || 0}
            />
            <SummaryCard
              icon={
                <FaSortAmountUpAlt className="h-8  w-12 mr-4 text-green-300" />
              }
              name="Online Sales Amount"
              amount={CurrencyFormatter(
                attendantSummary?.data?.summary?.totalSales
              )}
            />
          </div>
        </>
        <div className="grid mt-9 grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3  py-1">
          {attendantTabData.map((dt) => (
            <Fragment key={dt.id}>
              <CardButton
                name={dt.name}
                icon={dt.icon}
                link={dt.link}
                height={"98px"}
                activeBtn={cardName.tab}
                onClick={() => {
                  handleTabChange(dt.name?.toLowerCase());
                }}
              />
            </Fragment>
          ))}
        </div>
        {cardName.tab === "meter readings" && (
          <>
            <Transaction
              headCells={meterReadingsHeadCells}
              transactions={handledMeterReadingResponse}
              transactionResult={meterReadingResult}
            />
          </>
        )}
        {cardName.tab === "transaction" && (
          <>
            <div className="w-fit mr-auto flex items-center gap-x-4 px-4 h-24 py-4 mt-4">
              <h6>Filter By: </h6>
              <SelectInput
                tabData={[
                  {
                    label: "Today",
                    value: "today",
                    id: "today",
                  },
                  {
                    label: "Current Month",
                    value: "currentMonth",
                    id: "currentMonth",
                  },
                  {
                    label: "Current Year",
                    value: "currentYear",
                    id: "currentYear",
                  },
                ]}
                filteredValue={filterParam?.date}
                onChange={(e) =>
                  setFilterParam({ ...filterParam, date: e.target.value })
                }
              />
            </div>
            <Transaction
              headCells={headCells}
              transactions={handledAPIResponse}
              transactionResult={allTransactionsResult}
            />
          </>
        )}
      </article>
    </section>
  );
}
